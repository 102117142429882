@charset "utf-8";
/*
 *   ___ _                   _      
 *  | __| |___ _ __  ___ _ _| |_ ___
 *  | _|| / -_) '  \/ -_) ' \  _(_-<
 *  |___|_\___|_|_|_\___|_||_\__/__/
 * 
 */

html		{ overflow-x: hidden;	}
body
{
	font-family	: "Ubuntu", sans-serif;
	color: white;
}

h1, h2, h3, h4
{
	text-align: center;
}

section, article, details
{
	margin			: 1vmin;
	padding			: 1vmin;
	
	border-radius	: 5px;
}

iframe	{ border-radius: 5px;	}
iframe, object, embed	{ width: 100%; }

summary	{ cursor: pointer;		}
p		{ line-height: 1.5em;	}
pre
{
	position: relative;
	
	max-height: 30rem;
	
	border-radius: 0.3em;
}
code
{
/*	white-space: pre-wrap !important;*/
	word-break: break-all !important;
}
pre > code
{
	display: block;
}
pre a, pre a:hover, pre a:active
{
	color: inherit;
	font-weight: inherit;
}

/* Prism 'toolbar' */
.code-toolbar > .toolbar {
	opacity: 1 !important;
	top: 0.5em !important; right: 0.5em !important;
}
.toolbar-item > span {
	/* position: absolute; right: 0.5em; */
	display: inline-block;
	padding: .25em .45em !important;
	
	font-size: 1em !important;
	color: white !important;
	background: rgba(136,90,216,.6) !important;
	border-radius: .2rem !important;
	
	box-shadow: none !important;
}
/*pre::before
{
	content: attr(data-language);
	position: absolute; top: 0; right: 0;
	
	padding: 0.2rem 0.4rem;
	
	background: #a8a6a4;
	border-top-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
	
	font-family: sans-serif;
	font-size: small;
	text-shadow: none;
}*/

blockquote
{
	position		: relative;
	margin			: 0.5rem;
	padding			: 0.5rem 0.5rem 0.5rem 2rem;
	
	border-left		: 3px solid rgba(255, 255, 255, 0.6);
	border-radius		: 0.2rem;
}
blockquote::before
{
	content		: "\201C"; /* smart quote start */
	
	position	: absolute; top: 0; left: 0;
	
	line-height	: 0.8;
	font-family	: monospace;
	font-size	: 18rem;
	
	z-index		: -1;
	opacity		: 0.4;
}

a
{
	opacity		: 1;
	
	font-weight	: bold;
	color		: #eeeeee;
	
	transition	: color 0.25s;
}
a:hover		{ color: #f5f5f5; }
a:active	{ color: white; }
h1 a, h2 a, h3 a	{ text-decoration: none; }

img
{
	margin: 5px;
	border: 0;
}


hr
{
	margin			: 5px 25px;
	
	border			: 1px solid white;
	border-radius	: 3px;
}

td, th
{
	padding			: 10px;
	
	vertical-align	: top;
	
	background		: rgba(240, 240, 240, 0.3);
	border-radius	: 5px;
}

button, input[type=submit]
{
	margin			: 5px 8px;
	background		: #eeeeee;
	
	border			: 3px outset white;
	border-radius	: 5px;
	
	cursor			: pointer;
	
	transition		: all 0.25s;
}
button:active, input[type=submit]:active
{
	background	: #bbbbbb;
	border		: 3px inset #cccccc;
}
input.large,
button.large,
.largebutton
{
	position		: relative; top: 0;
	
	margin			: 10px 15px;
	padding			: 10px 15px;
	
	background		: rgba(240, 240, 240, 0.4);
	border-radius	: 5px;
	
	box-shadow		: 0 5px 10px rgba(177, 177, 177, 0.3);
	
	transition		: all 0.25s;
}
input.large:active,
button.large:active,
.largebutton:active
{
	top			: 5px;
	
	box-shadow	: 0 2px 2px rgba(177, 177, 177, 0.3);
}

/* ASDS */
div[data-mode=stealth]	{ display: none;		}


/*
 *    ___ _                    
 *   / __| |__ _ ______ ___ ___
 *  | (__| / _` (_-<_-</ -_|_-<
 *   \___|_\__,_/__/__/\___/__/
 *                            
 */

.block			{ display: block;			}
.inline			{ display: inline;			}
.inline.block	{ display: inline-block;	}

.left-text		{ text-align: left;			}
.centretext		{ text-align: center;		}
.right-text		{ text-align: right;		}

.invisilink		{ text-decoration: none;	}
.invisilist     { list-style-type: none; margin: 5px; padding: 5px; }
.invisilist.structural > li { display: inline; } /* Useful for improving readability in text-based web browsers */

.abs			{ position	: absolute;				}
.abs.top		{ top		: 0; left: 0; right: 0;	}

.float.right	{ float		: right;				}
.float.left		{ float		: left;					}

.fixed			{ position	: fixed;				}
.fixed.top		{ top		: 0;					}
.fixed.right	{ right		: 0;					}
.fixed.bottom	{ bottom	: 0;					}
.fixed.left		{ left		: 0;					}

.faded			{ opacity	: 0.4;					}
.x-small		{ font-size	: x-small;				}
.x-large		{ font-size	: x-large;				}

.clickable		{ cursor: pointer;					}

.small-spacing	{ margin: 2px 4px;	padding: 2px 4px;	}
.med-spacing	{ margin: 5px 8px;	padding: 5px 8px;	}
.high-spacing	{ margin: 8px 10px;	padding: 8px 10px;	}

.inline-image	{ vertical-align: middle;	}

.nomargin		{ margin: 0; }

.multicolumn-auto
{
	column-count: 3; /* maximum of 3 columns */
	column-width: 10em;
	column-gap: 1em;
}
.multicolumn-auto > li
{
	page-break-inside: avoid;
	-webkit-column-break-inside: avoid;
	break-inside: avoid;
}


/* Open Iconic icons */
.oi::before
{
	margin: 5px 5px 0 5px;
}
